html, body, #root, .App {
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  /* background-color: #F5F5F5; */
  background-image: url(../images/bg-image.jpg);
  background-repeat: repeat;
  background-position: center ;
  background-size: auto;
  background-color: #EFF0EB;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
}

tr > td{
  font-size: 16px;
}

.color-blue{
  color: #3F51B5;
}

p{
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

.para-bm {
  margin-bottom: 3px;
  font-family: 'Montserrat', sans-serif;
}
.mainNavBar{
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.fw-500{
  font-weight: 500;
}

.font-16{
  font-size: 16px;
}

.require-field{
  color : #FFC400;
}


.mainNavBar p {
  height: 100%;
  color: white;
  text-decoration: none;
  font-family: 'Montserrat';
  margin: 0;
  padding: 10px;
}

/* chip list style */
.chip-list{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-top: 15px;
}

.mt-20{
  margin-top: 20px;
}

.mt-40{
  margin-top: 40px;
}
.mt-25{
  margin-top: 25px;
}
.mt-50{
  margin-top: 50px;
}

.jCentered{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.navColor{
  background-color: #3f51b5;
  width: 100%;
  overflow: hidden;
}

.navLast{
  margin-right: 20px;
}

.marginTop
{
  margin-top: 0px;
}

.login-bg{
  background: url(../images/login_bg_2.jpg);
  /* background-image: url(../images/login_bg_4.jpg); */
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.overlay { 
   width: 100%;  
   height: 100%; 
   display: flex;
   flex-direction: column;
  justify-content: flex-start;
  background-color: rgb(0,0,0,0.8); /* Black background with opacity */
  z-index: 2; 
}

.overlay2 { 
  width: 100%;  
  height: 100%; 
  display: flex;
  flex-direction: column;
 justify-content: flex-start;
 background-color: rgb(0,0,0,0.4); /* Black background with opacity */
 z-index: 2; 
}

.boxContainer{
  margin: 20px 0px 0px 0px;
  border: 1px solid #b6d4d1 !important;
  padding: 20px 20px 20px 20px;
  border-radius: 2%;
  box-shadow: #23D160;
  background-color: white;
}

.filterIcon {
  color: white;
  background-color: #3f51b5;
  padding: 2px;
  border-radius: 5px;
  margin: 10px;
}

.horizontal-bar{
width: 20%;
border-radius: 10px; 
height: 1px;
margin: 20px auto;
/* background-color: #a5a58d; */
}

.refreshIcon
{
  color: white;
  background-color: #2dc653;
  padding: 2px;
  border-radius: 5px;
  margin: 10px;
}

.refreshIcon :hover{
  background-color: white;
}

.downloadArticles
{
  color: white;
  background-color: #3F51B5;
  padding: 2px;
  border-radius: 5px;
  margin: 10px;
}

.downloadArticles :hover{
  background-color: white;
}

.main_logo{
  margin-top: 40px;
 text-align: center;
}

.main_logo_client{
  margin-top: 30px;
  text-align: center;
}

.main_logo img{
  width: 150px;
  height: 150px;
}
.main_logo_client img{
  width: 200px;
  height: 200px;
}

.loginForm{
  padding: 20px;
  margin-top: 40px;
}

.loginForm label{
    color: white;
}

.loginTitle{
  align-content: center;
  margin-top: 20px;
}

.cloginTitle{
  align-content: center;
  margin: 5px 0px 5px 0px;
}

.cloginTitle  h1{
  flex: 1;
  justify-content: center;
  font-size: 40px;
  font-weight: bolder;
}

.loginTitle  h1{
  flex: 1;
  justify-content: center;
  /* text-align: center; */
  font-size: 30px;
  font-weight: bolder;
  color: #ffffff;
}

.clientLogin
{
  width: 80%;
  background-color: rgb(255,255,255,0.9);
  padding: 30px;
  border-radius: 20px;
  
}

.image-size{
  width: 200px !important;
  height: 200px !important;
  margin: 0px auto;
}

.btnColor{
  background-color: #3F51B5 !important;
  color: white !important;
}

.hide-me{
  display: none;
}

.btnColorGreen{
  background-color: #23D160 !important;
  color: white !important;
}

.btnColorRed{
  background-color: red !important;
  color: white !important;
}

.fontSettings
{
  font-size: 24px;
  font-weight: bold;
}

.dFlex{
  display: flex;
}

.dFlexGap{
  /* gap: 15px; */
}

.dFlexCol {
  flex-direction: column;
}

.flex-50{
  flex: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}

.flex-end{
  padding-top: 20px;
  flex: 50%;
  display: flex;
  align-items: flex-end;
}

.dFlexCenter{
  /* justify-content: center;
  align-items: center; */
  justify-content: flex-end;
  align-items: center;
}

.colorYellow{
  color : #FFC400 !important;
}

.cFlexRow {
  flex-direction: row;
}

.imageLogo{
  width: 100px;
  height: 100px;
}

.imageIcon{
  width: 40px;
  height: 40px;
}

.imageLogoSmall{
  width: 50px;
  height: 50px;
}  

.dFlexWeight{
  /* flex: 1; */
}

.jHeight{
  height: 220px;
}

.jBorder{
  padding: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5%;
}

.box-shadow{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.jMarginTop50{
  margin-top: 50px;
}

.jMarginBottom50{
  margin-bottom: 50px;
}
.jPadding{
  padding: 40px;
}

.bgWhite{
  /* background-color: #FBFCF7; */
  background-color: #FFFFFF;
}

.jTextCenter{
  text-align: center !important;
}

.jFontBold{
  font-weight: bold;
}

.jNoLinkDecor  a{
    text-decoration: none !important;
    color: #212529;
}

/* custom load list design */
.dFlexColLeft {
display: flex;
flex-direction: column;
 align-items: flex-start;
height: 110px;
}
.dFlexColRight {
  display: flex;
  flex-direction: column;
   align-items: flex-end;
  height: 110px;
}

.dFlexRow{
  display: flex;
  flex-direction:row;
  margin-top:auto;
  text-align: left;
  padding:0px;
  font-size:18px;
  width:100%
}
.roundIcon {
  font-size: 18px !important;
  color: green;
}

.hideContent
{
  display: none !important;
  position:absolute !important;
}

.roundIconHide {
  display: none !important;
}

.roundIconRed {
  font-size: 18px !important;
  color: red;
}
/* Font Size for custom list */
.cAvName{
  color: #001524 !important;
  font-size: 28px;
  line-height: 30px;
  text-align: left;
}
.cAvDate{
  font-size: 18px;
}

.cAvStatus{
  font-size: 18px;
  margin-top: auto;
  padding: 0px;
}

.cAvCycleName{
  text-align: left;
  margin: 6px 8px;
  font-weight: 500;
  font-size: 14px;
  padding: 0px;
}

.inUseStatus{
    font-size: 18px;
    margin-top: auto;
    padding: 0px;
    color: white;
    background-color: #3F51B5;
    padding: 2px 15px 2px 15px;
    border-radius: 15px;
}

.firstOrNOt{
  font-size: 18px;
  padding: 0px;
  color: white;
  background-color: #3F51B5;
}

.cAvCycle{
  /* color: #001524 !important; */
  font-size: 60px;
  line-height:50px;
  margin: 0px;
}

.cButton {
  border-radius: 15px !important;
  font-size: 12px !important;
}

/* --- custom load list design  end --- */

@media (max-width: 768px) {

  [class*="col-"] {
      margin-bottom: 15px;
  }
  .dFlexColLeft {
    height: 100px;
    }
    .dFlexColRight {
      height: 100px;
    }

  .dFlexColRight {
     align-items: flex-end;
  }
  /* Font Size for custom list */
  .cAvName{
    font-size: 26px;
    line-height: 30px;
  }
  .cAvDate{
    font-size: 16px;
  }
  
  .cButton {
    border-radius: 15px !important;
    font-size: 12px !important;
  }
  .dFlexRow{
    font-size: 16px;
  }
  .cAvStatus{
    font-size: 16px;
    margin-top: auto;
    padding: 0px;
  }
  .cAvCycleName{
    padding: 6px 8px;
    font-size: 14px;
    padding: 0px;
  }
  
  .cAvCycle{
    font-size: 60px;
    line-height:40px;
  }

}

@media (max-width: 600px) {
  
  .cAvName{
    font-size: 20px;
    line-height: 30px;
  }
  .cAvDate{
    font-size: 14px;
  }
  
  .cButton {
    border-radius: 12px !important;
    font-size: 10px !important;
  }
  .dFlexRow{
    font-size: 14px;
  }
  .cAvStatus{
    font-size: 14px;
    margin-top: auto;
    padding: 0px;
  }
  .cAvCycleName{
    padding: 6px 8px;
    font-size: 12px;
    padding: 0px;
  }

  .roundIcon {
    font-size: 14px !important;
    color: green;
  }

  
  .roundIconRed {
    font-size: 14px !important;
    color: red;
  }
  .cAvCycle{
    font-size: 60px;
    line-height:40px;
  }

  .jFontBold{
    font-size: x-large;
  }
}

@media (max-width: 450px) {
  .cAvName{
    font-size: 15px;
    line-height: 20px;
  }
  .cAvDate{
    font-size: 12px;
  }
  .cAvCycle{
    font-size: 40px;
    line-height:30px;
  }

  .cAvStatus{
    font-size: 12px;
    margin-top: auto;
    padding: 0px;
  }

  .cAvCycleName{
    padding: 6px 8px;
    font-size: 12px;
    padding: 0px;
  }

  
  .jFontBold{
    font-size: large;
  }


  
}