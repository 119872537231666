body {
  margin: 0;
  font-family: Montserrat, Roboto, -apple-system, BlinkMacSystemFont, 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Montserrat, Roboto, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Articles-per-load.tsx */
.apl{
  text-align: center;
  width: 100% !important;
  margin: 0 auto;
  font-size: 20px !important;
  background-color: #3F51B5 ;
  /* border: 1px solid #ced4da; */
  color: white;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 10px;
}

.cResultContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: sandybrown; */
}

.cResult{
  text-align: center;
  /* border: 1px solid #ced4da; */
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.aritclesList{
width: 100%;
text-align: center;
margin: 0 auto;
max-width: 600px;
/* border: 1px solid #ced4da; */
border-radius: 10px;
margin-bottom: 10px;
}


